import * as React from "react"
import { Link } from 'gatsby'


// markup
const AboutPage = () => {
    return (
        <>

            <header>
                <nav>
                    <Link to='/'>Home</Link>
                    <Link to='/about'>About</Link>
                </nav>
            </header>
            <main>
                <h1>About the Orchard Homes Ditch Company</h1>
                <p>The Orchard Homes Ditch Company is comprised of shareholders who own water rights, as decreed from the Clark Fork River which have been permitted to be transported through the ditch. The Orchard Homes Ditch Company maintains the ditch for the purpose of transporting irrigation water to water rights holders.</p>
                <p>The ditch is one of a series of irrigation ditches throughout the Missoula valley with the purpose to provide irrigation water. The ditch sends water to the neighborhoods and fields west of Reserve Street, as far as Hiberta Street. It irrigates commercial nurseries, corn fields and the River Road Community Garden, along with dozens of private lawns.</p>
                <p>The amount of irrigation water flow allowed in the ditch is regulated by the Federal Water Master (U.S. District Court Water Master) during the irrigation season, which normally runs between May 1 and October 1 each year.</p>
                <p>The ditch is an easement. The Orchard Homes Ditch Company does not own the land over which the ditch passes; however,  owners of property through which the ditch easement passes, whether ditch shareholders or not, are required to not impact the easement or block access for the Orchard Homes Ditch Company crew to monitor or clean the ditch.</p>
                <p>Holders of water rights assigned to the ditch must be shareholders of the Orchard Homes Ditch Company to receive the irrigation water.  The Orchard Homes Ditch Company delivers the water as determined by the Federal Water Master. The Orchard Homes Ditch Company has no role in determining waters rights, or the transfer and/or acquisition of water rights.</p>
            </main>
        </>
    )
}

export default AboutPage
